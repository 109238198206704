import debug from "debug";

const log = debug("websocket");


export default class Socket {

  constructor(endpoint, opts={}) {

    if (! endpoint) {
      endpoint = location.host;
      let proto = /https/i.test(location.protocol) ? "wss" : "ws";
      endpoint = `${proto}://${endpoint}/ws/`;
    }

    endpoint = endpoint.replace("https:", "wss:");
    endpoint = endpoint.replace("http:", "ws:");

    this.endpoint = endpoint;
    this.pending = [];
    this.counts = {};
    this.events = {};
    this.makePromise();
    this.ready = new Promise((r) => {
      this._ready = r;
    });

    // these are changed in connect(), they must be before the call
    this.connecting = false;
    this.connected = false;

    if (opts.connect === undefined ? true : opts.connect) {
      this.connect();
    }

    this.connectedTime = null;
    this.connectedDuration = null;
    this.consecutiveDisconnects = 0;
    this.baseTimeout = 1000;
    this.heartbeatTimeout = opts.heartbeatTimeout === undefined ? 60 * 1000 : opts.heartbeatTimeout
    this.timeout = this.baseTimeout;
    this.closed = false;
    this.autoreconnect = opts.autoreconnect === undefined ? true : opts.autoreconnect;

    window.wsevents = this.events;
  }

  addEventListener(event, func) {
    if (! this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(func);
  }

  removeEventListener(event, func) {
    if (this.events[event]) {
      let idx = this.events[event].indexOf(func);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  callEventListeners(event, data) {
    if (this.events[event]) {
      for (let func of this.events[event]) {
        func(data);
      }
    }
  }

  count(event) {
    this.counts[event] = (this.counts[event] || 0) + 1;
  }

  makePromise() {
    this.promise = new Promise((resolve) => {
      this._resolve = () => {
        resolve()
      };
    });
  }

  async ensureConnected() {
    await new Promise((r) => setTimeout(r, 1));
    if (this.connecting) {
      await this.promise;
    }
    else if (!this.connected) {
      await this.connect();
    }
  }

  async connect() {
    if (!this.connecting) {
      this.connecting = true;
      if (!this.worker) {
      // console.log("env", process.env, process.env.NO_WEBWORKERS);
        // const useFaker = process.env.NO_WEBWORKERS;
        // if (useFaker) {
        //   console.log("using ws faker");
        let WebsocketWorker = (await import("./websocket_worker.js")).default;
        this.worker = new WebsocketWorker();
        // } else {
        //   this.worker = new Worker("./websocket.worker.js");
        // }
        this.worker.onmessage = (e) => {
          let type = e.data.type;
          let data = e.data.data;
          log("message", e, type, data);

          if (type == "connect") {
            this.onConnect(data);
          } else if (type == "disconnect") {
            this.onDisconnect(data);
          } else if (type == "ready") {
            this._ready();
          } else if (data) {
            this.onMessage(data);
          }
        };
        this.worker.onerror = (e) => {
          console.error(e);
        };

        setTimeout(() => this.worker.sendMessage({ type: "ready" }), 10);
      }
      log("waiting for worker", this.events.connect);
      // if (this.events.connect) {
      //   delete this.events.connect;
      // }
      await this.ready;
      // if socket was disconnected, it is no longer closed
      this.closed = false;
      log(`connecting to ${this.endpoint}`);
      this.worker.postMessage({ action: "connect", endpoint: this.endpoint });
    }
  }

  onConnect(e) {
    log('*** connected! ***');
    this.connected = true;
    this.callEventListeners("connect", null);

    // pending messages
    for (let msg of this.pending) {
      log(`resending pending message ${msg[0]}`);
      this.emit(msg[0], msg[1]);
    }
    this.pending = [];

    this.count("connect");
    this.connectedTime = Date.now();
    this._resolve(true);
  }

  onMessage(packet) {
    // reset timeout
    this.timeout = this.baseTimeout;

    this.heartbeat();
    let data = JSON.parse(packet);
    this.callEventListeners("_message_", data);
    this.callEventListeners(data[0], data[1]);

    this.count(data[0]);
    this.count("_message");
  }

  getDisconnectReason(code) {
    return {
      "1000": "Normal Closure",
      "1001": "Going Away",
      "1002": "Protocol error",
      "1003": "Unsupported Data",
      "1004": "Reserved",
      "1005": "No Status Rcvd",
      "1006": "Abnormal Closure",
      "1007": "Invalid frame payload data",
      "1008": "Policy Violation",
      "1009": "Message Too Big",
      "1010": "Mandatory Ext",
      "1011": "Internal Server Error",
      "1015": "TLS handshake",
    }[code];
  }

  async onDisconnect(e) {
    let reason = this.getDisconnectReason(e.code);
    if (e.reason) {
      log(`disconnected (${reason} - ${e.reason})`);
    } else {
      log(`disconnected (${reason} : ${e.code})`);
    }

    this.connectedDuration = Date.now() - this.connectedTime;
    if (this.connectedDuration < 10000) {
      this.consecutiveDisconnects += 1
      await new Promise((r) => setTimeout(r, 1000));
    } else {
      this.consecutiveDisconnects = 0;
      log(`disconnected (${reason})`);
    }

    // log abnormal disconnects
    if (e.code != 1000 && (e.code != 1006 || ! this.counts._message)) {
      (async () => {

        let report = {
          code: e.code,
          reason: e.reason,
          rfc: this.getDisconnectReason(e.code),
          counts: this.counts,
          online: navigator.onLine,
          started: this._started,
          ended: Date.now(),
          waited: Date.now() - this._started
        };

        let connection = navigator.connection;
        if (connection) {
          report.connectionType = connection.type;
          report.effectiveType = connection.effectiveType;
          report.rtt = connection.rtt;
          report.downlink = connection.downlink;
        }

        // Raven.captureException(new Error(`socket info4`), {
        //   extra: report
        // });
      })();
    }

    this.connected = false;
    this.connecting = false;

    this._resolve(false);
    if (this._resolve_close) {
      this._resolve_close();
    }
    this.callEventListeners("disconnect", null);

    // reset heartbeat
    clearTimeout(this.__heartbeat);

    // attempt to reconnect
    clearTimeout(this.__reconnect);

    if (this.consecutiveDisconnects > 3) {
      console.warn('server is not connecting, quiting for a while');
      this.__reconnect = setTimeout(() => this.ensureConnected(), this.timeout * 100);
    } else if (! this.closed && this.autoreconnect) {
      this.makePromise();
      this.__reconnect = setTimeout(() => this.ensureConnected(), this.timeout);
      log(`Will attempt to reconnect in ${this.timeout}ms`);

      // dither reconnect time to prevent the thundering herd
      this.timeout += Math.floor(Math.random() * 5000) + this.timeout;
      this.timeout = Math.min(60 * 1000, this.timeout);
    }

    this.count("disconnect");
  }

  on(event, callback) {
    if (event === 'connect' && this.connected) {
      callback();
    }
    this.addEventListener(event, callback);
  }

  once(event, callback) {
    let listener = (data) => {
      this.removeEventListener(event, listener);
      callback(data);
    };
    this.addEventListener(event, listener);
  }

  async waitFor(event, timeout=10000) {
    return Promise.race([
      new Promise((r) => this.once(event, () => r)),
      new Promise((r) => setTimeout(r, timeout)),
    ]);
  }

  off(event) {
    if (this.events[event]) {
      delete this.events[event];
    }
  }

  emit(event, data={}, retry=true) {
    if (this.connected) {
      this.worker.postMessage({action: "emit", event, data});
      this.heartbeat();
      this.count("_emit");
    } else if (retry) {
      log(`Queuing message to send later: ${event}`);
      this.pending.push([event, data]);
      this.pending = this.pending.slice(0, 3);
    }
  }

  heartbeat() {
    if (this.heartbeatTimeout > 0) {
      // console.log('heartbeat');
      clearTimeout(this.__heartbeat);
      this.__heartbeat = setTimeout(() => {
        log("\u2764");
        this.worker.postMessage({action: "heartbeat"});
        this.count("_heartbeat");
      }, this.heartbeatTimeout);
    }
  }

  close() {
    // console.log('*** closed ***');
    this.closed = true;
    return new Promise(resolve => {
      this.worker.postMessage({action: "close"});
      this._resolve_close = resolve;
    });
  }
}
