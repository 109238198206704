import {create, sendwait, timer} from "./utils.js";

export default class SocketPlugin {
  install(app, host, opts={}) {
    app.config.globalProperties.$socketHost = host;
    app.config.globalProperties.$socketOpts = opts;
    app.config.globalProperties.$socket = create(host, opts);
    app.config.globalProperties.$sendwait = sendwait;
    app.config.globalProperties.$socketOpts = opts;
  }
}

export {create, sendwait, timer};