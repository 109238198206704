<style scoped>
.audio-element {
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;
}
.audio-element > * {
  min-width: 300px;
  flex: 1 1 50%;
}
</style>

<template>
  <div class="music-content flex-column">
    <Popover v-model:value="showModal">
      <MusicList @update:modelValue="handleChoice" pick />
    </Popover>
    <help-message name="music-clips-explanation" v-if="type === 'music'">
      {{ $t("music_content.music-help") }}
    </help-message>
    <help-message name="audio-clips-explanation" v-if="type === 'audio'">
      {{ $t("audio_content.audio-help") }}
    </help-message>
    <div
      class="audio-element padded flex-row flex-align-center flex-wrap"
      tabindex="0"
      @keypress.enter="showModal = true"
      @keypress.space="showModal = true"
    >
      <div v-if="value.blob_id">
        <p v-if="type === 'music'">
          {{ $t("music_content.play-music", { media: media?.name || value.blob_id }) }}
        </p>
        <p v-else>{{ $t("music_content.play-clip", { media: media?.name || value.blob_id }) }}</p>
        <div class="flex-column flex-left">
          <label for="volume">Volume ({{ Math.floor((value.volume || 1) * 100) }}%)</label>
          <input
            type="range"
            name="volume"
            min="0.01"
            step="0.01"
            max="1"
            :value="value.volume || 1"
            @input="$emit('update', { volume: parseFloat($event.target.value) })"
          />
        </div>
      </div>
      <div v-else>
        <b>{{ $t("music_content.silence-0") }}</b>
        <p v-if="type === 'music'">{{ $t("music_content.music-will-stop-playing") }}</p>
        <p v-else>{{ $t("music_content.silence-all-sound-effects") }}</p>
      </div>
      <div class="flex-column flex-stretch">
        <div v-if="value.blob_id" class="flex-column flex-stretch gap-1">
          <Blob_audio :blob="value.blob_id" :controls="true" :lazy="true" />
          <button @click="handleShow">Change...</button>
        </div>
        <div v-else>
          <button @click="handleShow">Choose...</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AudioSource } from "@/stores/story_defs";
import Popover from "@morphosis/base/components/Popover.vue";
import Media from "../../models/media";
import Blob_audio from "../blob_audio.vue";
import AudioModal from "../editor/audio_modal.vue";
import MusicList from "../editor/storyObjects/lists/MusicList.vue";

export default {
  name: "music-content",
  components: {
    AudioModal,
    Blob_audio,
    Popover,
    MusicList,
  },
  props: {
    story: Object,
    value: Object,
    type: {
      type: String,
      default() {
        return "music";
      },
    },
  },
  data() {
    return {
      loaded: false,
      media: null,
      showModal: false,
    };
  },
  computed: {
    fields() {
      const retval = [
        // {
        //   name: 'channel',
        //   label: 'Channel',
        //   default: 1,
        //   placeholder: 'Sound Channel 1',
        //   options: [
        //     { label: 'Sound Channel 1', value: 1 },
        //     { label: 'Sound Channel 2', value: 2 },
        //     { label: 'Music Channel 1', value: 11 },
        //     { label: 'Music Channel 2', value: 12 },
        //   ]
        // },
      ];
      // if (this.value.channel > 10) {
      //   retval.push({ name: 'loop', label: 'Loop?', type: 'checkbox' });
      // }
      retval.push({ name: "content" });
      return retval;
    },
    clip() {
      return this.audioOptions.find((a) => a.id == this.value.content);
    },
    audioOptions() {
      return this.story[this.type];
    },
  },
  watch: {
    async "value.blob_id"() {
      if (this.value.blob_id) {
        this.media = await Media.load(this.value.blob_id);
      } else {
        this.media = null;
      }
    },
  },
  async mounted() {
    this.media = await Media.load(this.value.blob_id);
  },
  methods: {
    async handleShow() {
      this.showModal = true;
      await this.$nextTick();
      const el = this.$el.querySelector("[tabindex]");
      if (el) {
        el.focus();
      }
    },
    handleChoice(media: AudioSource) {
      this.$emit("update", { blob_id: media.blob_id });
      this.showModal = false;
    },
    handleLoaded(e) {
      this.loaded = true;
      console.log("loaded", e);
    },
  },
};
</script>
