
export default class BaseDriver {
  async ready() {
    return true;
  }

  async length() {
    throw new Error('Not implemented');
  }

  async iterate(predicate) {
    throw new Error('Not implemented');
  }

  async setItem(name, data) {
    throw new Error('Not implemented');
  }

  async getItem(name, def) {
    throw new Error('Not implemented');
  }

  async removeItem(name) {
    throw new Error('Not implemented');
  }
}
