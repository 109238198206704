import debug from "debug";
import { mapStores } from "pinia";
import { useUserStore } from "../stores/user/users";

const log = debug("user");
// const log = (...args) => console.log('<user>', ...args);

export default {
  data() {
    return {
      disconnected: false,
    };
  },
  computed: {
    user() {
      return this.usersStore.current;
    },
    ...mapStores(useUserStore),
  },
  watch: {
    // "usersStore.current"() {
    //   console.log("user", this.usersStore.current);
    // },
  },
  async created() {
    await this.usersStore.ready;
  },
  async mounted() {
    await this.$nextTick();

    // this.createLoginPromise();
    // await new Promise(r => setTimeout(r, 1000 * 7));
    await this.initialLogin();

    this.$socket.on("connect", () => {
      console.warn("connected");
      log("connected", this.user);
      if (this.user && !this.user.token) {
        this.login();
      }
      this.disconnected = false;
    });
    this.$socket.on("disconnect", () => {
      this.disconnected = true;
      log("disconnected");
      console.warn("user disconnected");
    });
  },
  methods: {
    async initialLogin() {
      this.login();
    },
    async login() {
      return this.usersStore.login();
    },
  },
};
