import Media from "@/models/media";
import { FullStory } from "@/stores/story_defs";

export interface DirectionReference {
  scene: string;
  direction: string;
}

export interface MediaReference {
  id: string;
  type: string;
  permalink: string;
  cloud: boolean;
  media?: Media;
  references: DirectionReference[];
}

function addMedia(instance: Partial<MediaReference>, existing: MediaReference[]) {
  if (!instance.references) {
    instance.references = [];
  }
  const row = existing.find((r) => r.id === instance.id);
  if (!row) {
    existing.push(instance as MediaReference);
  } else {
    row.references.concat(instance.references);
  }
}

export async function collectMedia(story: FullStory): Promise<MediaReference[]> {
  const collection: MediaReference[] = [];

  for (const type of ["images", "backgrounds", "audio", "music"]) {
    for (const row of story[type]) {
      const key = row.blob_id;
      if (key) {
        addMedia({ id: key, type, permalink: row.permalink }, collection);
      }
    }
  }

  // get music and audio narration
  for (const scene of story.scenes) {
    for (const direction of scene.directions) {
      if (direction.type === "text" && direction.audio) {
        addMedia(
          {
            id: direction.audio,
            type: "narration",
            references: [{ scene: scene.id, direction: direction.id }],
          },
          collection,
        );
      }
      if (direction.type === "text" && direction.frozenAudio) {
        addMedia(
          {
            id: direction.frozenAudio,
            type: "frozen",
            references: [{ scene: scene.id, direction: direction.id }],
          },
          collection,
        );
      }
      if (direction.type === "music" && direction.blob_id) {
        addMedia(
          {
            id: direction.blob_id || direction.content,
            permalink: direction.permalink,
            type: "music",
            references: [{ scene: scene.id, direction: direction.id }],
          },
          collection,
        );
      }
    }
  }

  // characters
  for (const char of story.characters) {
    for (const emote of char.emotes) {
      const key = emote.blob_id;
      if (key) {
        addMedia(
          {
            id: key,
            type: "emotes",
            permalink: emote.permalink,
          },
          collection,
        );
      }
    }
  }

  return collection;
}
