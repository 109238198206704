import { Capacitor } from "@capacitor/core";
import { Encoding } from "@capacitor/filesystem";
import localforage from "localforage";
import { PREFIX } from "../utils/prefix";
import { FileStorage, StorageEngine } from "./file_storage";

export function pickStorage<T>(
  name: string,
  ext?: string,
  binary: boolean = false
): StorageEngine<T> {
  if (Capacitor.isNativePlatform()) {
    return new FileStorage<T>({ name, encoding: binary ? null : Encoding.UTF8, ext });
  }

  const engine = new LocalForageProxy<T>(name);
  engine.migrate();

  return engine;
}

export class LocalForageProxy<T> {
  name: string;
  store: LocalForage;

  constructor(name) {
    this.name = name;
    this.store = localforage.createInstance({ name: PREFIX, storeName: name });
  }

  async getItem(key: string, useAsDefault: T): Promise<T> {
    return (await this.store.getItem(key)) || useAsDefault;
  }

  async setItem(key: string, value: T): Promise<void> {
    await this.store.setItem(key, value);
  }

  async removeItem(key: string): Promise<void> {
    await this.store.removeItem(key);
  }

  async clear() {
    await this.store.clear();
  }

  async iterate(callback: (value: T, key: string) => boolean | undefined): Promise<void> {
    await this.store.iterate(callback);
  }

  async migrate() {
    // using localforage
    // check for migration needed
    const oldname = `${PREFIX}:${this.name}`;
    if ((await indexedDB.databases()).map((db) => db.name).includes(oldname)) {
      const oldstore = localforage.createInstance({ name: oldname });
      await oldstore.iterate(async (value, key) => {
        await this.store.setItem(key, value);
      });

      localforage.dropInstance({ name: oldname });
      console.error("deleted old store");
    }
  }
}
