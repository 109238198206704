<template>
  <!-- <div class="reader-content-item flex-static"> -->
  <animation
    :id="animation"
    :animations="story.animation"
    :play-reverse="false"
    class="reader-content-item flex-static"
    tag="div"
    appear
  >
    <!-- <div class="reader-content-item flex-static"> -->
    <height-transition
      class="reader-content-item flex-static"
      @transitionstart="handleHeightTransitionStart"
      @transitionend="handleHeightTransitionEnd"
      :steps="transitionSteps"
    >
      <div
        v-if="block.type === 'text'"
        class="text-content2"
        :class="{
          [`position-${position}`]: true,
        }"
      >
        <ImageAura v-if="emote?.blob_id" :size="emoteWidth" :value="aura" :position="position" />
        <transition-group appear tag="span" name="fade" class="text-block-emote flex-column">
          <img
            v-if="emote && emote.permalink"
            :src="emote.permalink"
            :style="{
              width: emoteWidthPx,
              margin: character && character.padding ? `${character.padding}px` : false,
            }"
          />
          <blob-image
            v-else-if="emote && emote.blob_id"
            :blob="emote.blob_id"
            :style="{
              width: emoteWidthPx,
              margin: character && character.padding ? `${character.padding}px` : false,
            }"
          />
          <div v-else-if="label" key="image-filler" class="image-filler" />
          <typer
            key="label"
            v-if="block.type === 'text' && label"
            class="text-block-label"
            ref="label"
            :text="label"
            :style="
              textLabelStyle({
                'min-width': emoteWidthPx,
              })
            "
            :speed="story.settings.typeSpeed"
            :particle-config="
              styleObject && styleObject.particlesEnabled ? styleObject.particles : null
            "
            :animation-config="styleObject ? styleObject.animation : null"
            :animations="story.animation"
            :story="story"
            :variables="tags"
            :allowImages="true"
          />
        </transition-group>

        <typer
          class="text-block"
          ref="text"
          :text="block.content"
          :style="textStyle(styleObject)"
          :speed="(block ? block.speed : null) || story.settings.typeSpeed"
          :particle-config="
            styleObject && styleObject.particlesEnabled ? styleObject.particles : null
          "
          :animation-config="styleObject ? styleObject.animation : null"
          :animations="story.animation"
          :story="story"
          :variables="tags"
          :allowImages="true"
          @type="handleTypeSound"
          @end="finished(block)"
        />
      </div>
      <template v-else-if="block.type === 'choice'">
        <div v-if="thought" class="thought" :style="textStyle(styleObject)" @click="thought = ''">
          <typer
            ref="thought"
            :text="thought"
            :speed="story.settings.typeSpeed"
            :story="story"
            :variables="tags"
            :allowImages="true"
            @end="thoughtfinished"
          />
        </div>
        <div class="choice" @click="handleChoice" :style="textStyle(styleObject)" key="choice">
          <typer
            ref="text"
            :text="block.label"
            :speed="story.settings.typeSpeed"
            :story="story"
            :variables="tags"
            :allowImages="true"
          />
          <!-- @end="finished(block)" -->
          <!-- :animationConfig="{ enter: animation }"
          :animations="story.animation" -->
          <div class="picks" v-if="myPicks.length > 0">
            <div class="pick" v-for="pick in myPicks" :key="pick.id">
              {{ pick.username }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="block.type === 'choice-timer'">
        <a href="#" @click.prevent :style="textStyle(styleObject)" class="timer flex-row">
          <div class="timer-text row flex-left flex-static">
            <div class="padded"><i class="fa fa-clock-o" /></div>
            <div class="padded">{{ (timeLeft / 1000).toFixed(0) }}</div>
          </div>
          <div class="progress">
            <div
              :style="{ width: `${(Math.max(0, timeLeft - 0) / block.timer) * 100}%` }"
              class="progress-meter"
            />
          </div>
        </a>
      </template>
      <template v-else-if="block.type === 'image'">
        <div class="image">
          <blob-image
            v-if="image"
            :blob="image.blob_id"
            @load="finished(block)"
            @error="error = $event.toString()"
          />
          <typer v-if="error" :text="error" @end="finished(block)" />
        </div>
      </template>
      <div v-else-if="block.type === 'input'" class="input-content" :style="textLabelStyle({})">
        <typer
          v-if="block.label"
          :text="block.label"
          :speed="story.settings.typeSpeed"
          :story="story"
          :variables="tags"
          :allowImages="true"
        />
        <form
          class="flex-row flex-align-center"
          key="input"
          @submit.prevent="
            $emit('user-input', input);
            finished(block);
          "
        >
          <a
            href="#"
            @click.prevent="useSuggestion"
            v-if="block.suggestions && block.suggestions.length"
          >
            <i class="fa fa-random" />
          </a>
          <input
            v-if="!tag || tag.format === 'text'"
            v-model="input"
            type="text"
            required
            :maxlength="block.max"
          />
          <input
            type="number"
            v-else
            v-model.number="input"
            required
            :max="block.max"
            :step="tag.precision ? 1 / 10 ** tag.precision : 1"
          />
          <button><i class="fa fa-check" /></button>
        </form>
      </div>
      <div
        v-else-if="block.type === 'achievement'"
        class="achievement-content text-block flex-column"
        :style="textStyle(styleObject)"
      >
        <typer
          v-if="block.title"
          class="achievement-title"
          ref="label"
          :text="block.title || ''"
          :style="textStyle(block.titleStyle, {})"
          :story="story"
          :variables="tags"
          :allowImages="true"
        />
        <typer
          class="achievement-text"
          ref="text"
          :text="block.content || ''"
          :story="story"
          :variables="tags"
          :allowImages="true"
          @end="finished(block)"
        />
      </div>

      <div v-else-if="block.type === 'option'">
        <div class="option flex-align-stretch" :style="textStyle(styleObject)">
          <!-- @click.prevent="$emit('user-input', input); finished(block)" -->
          <div
            v-if="(block.min || 0) === 0 && (block.max || 1) === (block.step || 1)"
            @click="$emit('option', { action: 'toggle', option: block })"
            class="padded flex-static option-input"
          >
            <i v-if="optionChoices[block.id] > 0" class="fa fa-check-square" />
            <i v-else class="fa fa-square" />
          </div>
          <div v-else class="flex-static flex-row flex-align-center option-input option-roller">
            <div class="padded" @click="$emit('option', { action: 'down', option: block })">
              <i class="fa fa-chevron-left" />
            </div>
            <div>{{ optionChoices[block.id] || 0 }}</div>
            <div class="padded" @click="$emit('option', { action: 'up', option: block })">
              <i class="fa fa-chevron-right" />
            </div>
          </div>
          <div class="option-label">
            <typer
              :text="block.label || block.content"
              :story="story"
              :variables="tags"
              :allowImages="true"
              @end="finished(block)"
              class="padded"
            />
          </div>
        </div>
      </div>

      <div v-else-if="block.type === 'video'" class="video flex-column flex-center">
        <iframe
          v-if="block.youtube"
          :src="`https://www.youtube-nocookie.com/embed/${block.youtube}`"
          :title="$t('video_content.youtube-video-player')"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          autoplay
        />
      </div>
      <template v-else-if="block.type === 'battle'">
        <battle
          :contestants="readerStore.combatants"
          :rounds="block.rounds"
          :story="story"
          @character-image="handleBattleCharacterImage"
          @finished="handleBattleOver"
        />
      </template>
      <template v-else>
        <p class="card">Don't know what this is: {{ block }}</p>
      </template>
    </height-transition>
  </animation>
</template>

<script>
import ImageAura from "@/components/character/ImageAura.vue";
import { mapStores } from "pinia";
import { textStyle } from "../../utils/tags";
import Battle from "../battle.vue";
import HeightTransition from "./height_transition.vue";
import { useReaderStore } from "./store";
//import TypeSound from '@/assets/type.mp3';

export default {
  name: "ReaderContent",
  components: {
    HeightTransition,
    Battle,
    ImageAura,
  },
  props: {
    story: Object,
    picks: Object,
    block: Object,
    first: Boolean,
    last: Boolean,
    optionChoices: Object,
    tags: Object,
    idle: { type: Boolean },
  },
  data() {
    return {
      completed: {},
      error: null,
      input: null,
      inputPoints: this.block.points,
      timer: this.block.timer ? Date.now() : 0,
      timeLeft: this.block.timer || 0,
      thought: "",
      thoughtIdx: -1,
      isThoughtfinished: true,
      isInTransition: false,
      transitionPromise: null,
      transitionPromiseResolve: null,
      typeSounds: [],
      audio: null,
    };
  },
  computed: {
    styleObject() {
      let style = this.story.styles.find((s) => s.default);
      if (this.block.type === "choice" || this.block.type === "choice-timer") {
        style = this.story.styles.find((s) => s.default_choice) || style;
      }
      let styleId = [this.character?.style, this.emote?.style, this.block.style];
      styleId = styleId.filter((a) => a).pop();
      if (styleId) {
        style = this.story.styles.find((s) => s.id === styleId) || style;
      }
      return style;
    },
    myPicks() {
      const retval = [];
      for (const player in this.picks) {
        if (this.picks[player].pick === this.block.id) {
          retval.push({ id: player, username: this.picks[player].username });
        }
      }
      return retval;
    },
    tag() {
      if (this.block.type === "input") {
        return this.story.tags.find((t) => t.id === this.block.content);
      }
      return null;
    },
    animation() {
      let animation = this.block.animationId;
      if (this.block.type === "choice") {
        animation = animation || this.story.settings.animationChoice;
      }
      animation = animation || this.story.settings.animation;
      if (!animation) {
        const animations = this.story.animations || [];
        if (animations.length > 0) {
          return animations[0].id;
        }
      }
      return animation;
    },
    character() {
      let charId = this.block.character;
      if (this.block.charTag) {
        charId = this.tags[this.block.charTag];
      }
      return this.story.characters.find((c) => c.id === charId);
    },
    emote() {
      let retval = null;
      if (this.character) {
        if (this.block.emoteName) {
          retval = this.character.emotes.find((e) => e.name === this.block.emoteName);
        }
        if (!retval) {
          retval = this.character.emotes.find((e) => e.id === this.block.state);
        }
      }
      return retval;
    },
    label() {
      if (this.block.label) {
        return this.block.label;
      }
      if (this.emote) {
        return this.emote.label;
      }
      return "";
    },
    position() {
      let retval = "left";
      if (this.emote?.position) {
        retval = this.emote.position;
      }
      if (this.block.position) {
        retval = this.block.position;
      }
      if (!retval || retval === "undefined") {
        retval = "left";
      }
      return retval;
    },
    aura() {
      let retval = {
        color: "white",
      };
      if (this.emote?.auraConfig) {
        retval = this.emote.auraConfig;
      } else if (this.character?.auraConfig) {
        retval = this.character.auraConfig;
      }
      return retval;
    },
    image() {
      if (this.block.type === "image") {
        const img = this.story.images.find((i) => i.id === this.block.content);
        return img;
      }
    },
    transitionSteps() {
      let steps = Math.max(0, this.block.speed || this.story.settings.typeSpeed || 5);
      return steps;
    },
    ...mapStores(useReaderStore),
    emoteWidth() {
      const width = this.character?.width || this.story.settings?.emoteWidth || 150;
      return width;
    },
    emoteWidthPx() {
      return `${this.emoteWidth}px`;
    },
  },
  async mounted() {
    if (["video", "choice", "contestant"].includes(this.block.type)) {
      this.$emit("finished");
    } else if (this.block.timer) {
      this.$emit("finished");
      this.countdown();
    }
    if (this.block.type === "input" && this.block.array) {
      const input = {};
      for (const row of this.block.array) {
        input[row.id] = row.start || 0;
      }
      this.input = input;
    }
  },
  watch: {
    isInTransition() {
      if (this.transitionPromise) {
        this.transitionPromiseResolve();
      }
    },
  },
  unmounted() {
    clearTimeout(this.__countdown);

    for (const old of this.typeSounds) {
      old.pause();
    }
    if (this.audio) {
      this.audio.pause();
    }
  },
  methods: {
    hurry() {
      if (this.audio) {
        this.audio.pause();
      }
      for (const block of ["text", "label"]) {
        const ref = this.$refs[block];
        if (ref && ref.speedup) {
          ref.speedup();
        }
      }
    },
    countdown() {
      this.timeLeft = this.block.timer - (Date.now() - this.timer);
      if (this.timeLeft <= 0) {
        this.timeLeft = 0;
      } else {
        this.__countdown = setTimeout(() => this.countdown(), 1000 / 120);
      }
    },
    async useSuggestion() {
      if (this.block.suggestions && this.block.suggestions.length > 0) {
        const idx = Math.floor(Math.random() * this.block.suggestions.length);
        this.input = this.block.suggestions[idx];
      }
    },
    thoughtfinished() {
      this.isThoughtfinished = true;
    },
    handleChoice() {
      if (this.$refs.thought && !this.isThoughtfinished) {
        this.$refs.thought.speedup();
        this.isThoughtfinished = true;
      } else {
        if (!this.block.thoughts || this.thoughtIdx >= this.block.thoughts.length) {
          if (this.idle) {
            this.$emit("choice", this.block.id);
          } else {
            console.log("is not idle, ignoring choice");
          }
        } else {
          this.thoughtIdx += 1;
          this.isThoughtfinished = false;
          this.thought = this.block.thoughts[this.thoughtIdx];
        }
      }
    },
    waitForTransitionEnd() {
      if (this.isInTransition) {
        this.transitionPromise = new Promise((resolve) => {
          this.transitionPromiseResolve = resolve;
        });
        return this.transitionPromise;
      }
    },
    handleHeightTransitionStart() {
      this.isInTransition = true;
    },
    handleHeightTransitionEnd() {
      this.isInTransition = false;
    },
    async finished(block) {
      // console.log("finished block", block);
      // await this.waitForTransitionEnd();
      this.$emit("finished", block);
    },
    textStyleId(id, extra = {}) {
      let style = this.story.styles.find((s) => s.default);

      if (this.block.type === "choice" || this.block.type === "choice-timer") {
        style = this.story.styles.find((s) => s.default_choice) || style;
      }
      if (id) {
        style = this.story.styles.find((s) => s.id === id) || style;
      }

      const retval = textStyle(style);
      for (const prop in extra) {
        retval[prop] = extra[prop];
      }
      return retval;
    },
    textLabelStyle(extra) {
      let style = this.story.styles.find((s) => s.default);
      if (this.story.settings.textLabelStyle) {
        style = this.story.styles.find((s) => s.id == this.story.settings.textLabelStyle) || style;
      }
      const retval = textStyle(style);
      for (const prop in extra) {
        retval[prop] = extra[prop];
      }
      return retval;
    },
    assignPoints(element, amount = 1) {
      if (amount > 0) {
        if (this.inputPoints >= amount) {
          this.input[element.id] += amount;
          this.inputPoints -= amount;
        }
      } else {
        if (element.start <= this.input[element.id] + amount) {
          this.input[element.id] += amount;
          this.inputPoints -= amount;
        }
      }
    },
    handleTypeSound(e) {
      // const audio = new Audio(TypeSound);
      // audio.play();
      // this.typeSounds.push(audio);
      // while (this.typeSounds.length > 12) {
      //   const old = this.typeSounds.shift();
      //   old.pause();
      // }
    },
    textStyle,
    async handleBattleCharacterImage(e) {
      console.log(e);
      // const stateImage = e.image;
      // await this.setCharacterImage(
      //   stateImage ? stateImage : null,
      //   e.position,
      //   stateImage ? e.aura : null,
      // );
    },
    async handleBattleOver(winner) {
      console.log("over", winner, this.block.id);
      if (this.block.result) {
        this.readerStore.reader.recordChoice({
          effects: [
            {
              tag: this.block.result,
              op: ":",
              value: winner,
            },
          ],
        });
      }
      this.finished(this.block);
      this.readerStore.reader.next();
      this.completed[this.block.id] = true;
      this.readerStore.contestants = [];
    },
  },
};
</script>

<style scoped>
.image-aura {
  /* border: 2px solid red; */
  left: 5px /* calc(var(--auraSize) * 0) */;
}
@media (min-width: 1000px) {
  .image-aura {
    left: 75px;
    /* border: 2px solid green; */
  }
}

.reader-content-item {
  position: relative;
  /* border: 4px dashed orange; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0 0 auto;
  min-height: min-content;
  /* padding-top: 10px; */
  margin: 3px 6px;
  transform-origin: center center;
  overflow: hidden;
}

#app .reader-content-item .text-content2 {
  overflow: visible;
  position: relative;
}

#app .input-content {
  border-radius: 5px;
  padding: 20px 20px 10px 20px;
}
#app .input-content input {
  margin: 0;
}
#app .input-content button {
  margin-left: 5px;
}
#app .input-row {
}
#app .input-row .button {
  background: var(--spark);
  color: var(--spark-color);
  min-width: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-shadow: none;
  font-size: 30px;
  margin: 5px;
}
.input-row .value {
  min-width: 45px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.9em;
}
.input-row .value.based {
  font-size: 0.75em;
  opacity: 0.7;
  font-weight: normal;
}

.lobby-content {
  max-width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
}

/*
#app .story-read .text-content {
  max-height: max-content;
} */

.reader-content-item .text-block-label {
  position: absolute;
  display: block;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  /* width: min-content; */
  flex: 0 0 auto;
  max-width: calc(min(600px, 90vw, 100%));
  bottom: -15px;
  z-index: 5;
  /* transform: translate(0, 120px); */
  /* box-shadow: 0 5px 10px var(--story-color); */
  /* opacity: 0.1; */
}
.position-center .text-block-label {
  left: 50%;
  right: 50%;
  translate: -50% 0px;
}
.position-right .text-block-label {
  left: auto;
}
.reader-content-item .text-block {
  display: block;
  padding: 20px;
  border-radius: 5px;
}
.reader-content-item .text-block-emote {
  z-index: 3;
  position: relative;
  margin-left: 15px;
  overflow: visible;
}
.reader-content-item .image-filler {
  min-height: 27px;
}
.position-center .text-block-emote {
  align-self: center;
  transform: none;
}
.position-right .text-block-emote {
  align-self: flex-end;
  /* transform: translate(-15px, 0px); */
  margin-right: 15px;
}
.position-right .text-block-emote img {
  transform: scale(-1, 1);
  z-index: 5;
  position: relative;
}

.reader-content-item .choice {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
}
.reader-content-item .choice .typer {
  flex: 1 1 auto;
}
.reader-content-item .choice .picks {
  margin: -20px -20px -20px 0;
  flex: 0 1 auto;
  max-width: 25%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  overflow: hidden;
}
.reader-content-item .choice .pick {
  flex: 0 0 auto;
  font-size: 10px;
  right: 0;
  padding: 2px 5px;
  background: var(--spark);
  color: var(--spark_text);
  writing-mode: vertical-lr;
  text-shadow: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    Verdana, sans-serif;
  max-height: 60px;
  border-radius: 0 0 4px 4px;
}

.reader-content-item .option {
  display: flex;
  flex-direction: row;
  border-radius: calc(var(--softness));
  margin: 0 !important;
}
.reader-content-item .option .option-input {
  flex: 0 0 100px;
  align-items: center;
  color: var(--spark_text);
  background: var(--spark);
  text-shadow: none;
  border-radius: calc(var(--softness));
  align-items: center;
}
.reader-content-item .option-label {
}
.reader-content-item .option > * {
  align-items: flex-start;
  justify-content: center;
  padding: calc(var(--padding) * 2);
  margin: 0;
}

.reader-content-item .thought {
  margin: 15px;
  padding: 20px;
  border-radius: 25px;
  position: relative;
  z-index: 2;
  transition: height 2s;
}
.reader-content-item .thought::after {
  content: " ";
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  bottom: -15px;
  z-index: 1;
  height: 0px;
  background: var(--border-color, var(--style-bg, white));
  width: 50px;
  height: 15px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.reader-content-item .video iframe {
  height: calc(100vw - var(--padding) * 2);
  width: calc(100vw - var(--padding) * 2);
  border-radius: var(--softness);
}

.reader-content-item .image {
  margin: auto;
  flex: 1 1 auto;
  max-width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reader-content-item .image img {
  max-width: 100%;
}

.reader-content-item .timer {
  border-radius: 5px;
}
.timer-text {
  width: 150px;
}

.progress {
  background: var(--spark_text);
  border: 1px solid var(--spark);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}
.progress-meter {
  background: var(--spark);
  box-shadow: 0 0 5px 10px var(--spark);
  height: 20px;
  border-radius: 10px;
  flex: 0 0 auto;
}

.rotate-in-enter-active,
.rotate-in-leave-active {
  transition: transform 2000ms;
}
.rotate-in-enter-from,
.rotate-in-leave-to {
  transform: rotate(-30deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 600ms !important;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.emotefade-enter-active,
.emotefade-leave-active {
  animation: emotefade 1s forwards;
}

@keyframes emotefade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .achievement-content {} */
/* .achievement-title {} */
/* .achievement-text {} */

@media (min-width: 1000px) {
  .reader-content-item .text-block,
  .reader-content-item .choice,
  .input-content {
    margin: 0 60px;
  }
  .reader-content-item .text-block-emote {
    padding: 0 60px;
  }
  .reader-content-item .timer {
    margin: 0 90px;
  }
}
</style>
