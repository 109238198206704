<template>
  <div class="background-content">
    <div class="flex-column">
      <div class="flex-row wrap gap">
        <em class="responsive-main content" v-if="! story.backgrounds || story.backgrounds.length === 0">
          <router-link :to="{name: 'backgrounds'}" class="create-background">
            {{ $t('direction.create-backgrounds') }}
          </router-link>
        </em>
        <div class="responsive-main content" v-else-if="editLevel == 'choose'">
          <Popover :value="true" :ref="`bg-${value.id}`" @show="editLevel = ''">
            <BackgroundList
              v-model:action="action"
              @update:id="update({content: $event})"
              @update:value="editLevel = ''; update({content: $event.id})"
              :story="story"
              :id="background ? background.id : null"
              :showHighlight="true"
            />
          </Popover>
        </div>
        <div
          v-else
          @click="editLevel = 'choose'"
          class="responsive-main flex-row"
        >
          <blob-background
            v-if="background"
            :blob="background.blob_id"
            class="bg flex-row flex-center flex-align-center"
            :thumb="true"
          />
          <div v-else class="bw flex-row flex-center flex-align-center">
            <p class="choose-background">{{ $t('background_content.choose-background') }}</p>
          </div>
        </div>
        <div
          v-if="story.settings.overlayout || useOverlayout"
          class="responsive-side bw background-color flex-row flex-align-center flex-center"
          :style="{
            'background-color': value.bgcolor ? value.bgcolor : 'lightgrey',
            'color': color,
          }"
        >
          <p>{{ $t('background_content.background-color') }}</p>
          <input
            type="color"
            :value="value.bgcolor || '#d3d3d3'"
            @input="update({bgcolor: $event.target.value})"
          >
        </div>
      </div>
    </div>
    <div>
      <div v-if="value.content" class="flex-row flex-align-center">
        <label>Size:</label>
        <select :value="value.size || 'cover'" @change="update({size: $event.target.value})">
          <option value="cover">{{ $t('background_content.cover-the-device-surface-some-of-the-image-will-be-cut-off') }}</option>
          <option value="contain">{{ $t('background_content.show-the-whole-background-short-sides-will-be-filled-with-the-background-color') }}</option>
          <option value="explore">{{ $t('background_content.allow-the-user-to-explore-the-background') }}</option>
        </select>
      </div>
      <div v-if="value.size === 'explore'" class="flex-row flex-align-center">
        <div class="padded flex-static"><b>Zoom:</b></div>
        <div class="explore-zoom flex-row flex-wrap flex-left padded">
          <div class="flex-row flex-align-center">
            <label>Start:</label>
            <input
              @change="update({zoom: $event.target.value})"
              :value="value.zoom"
              min="1"
              step="0.1"
              max="100.0"
              type="number"
              placeholder="1"
            />
          </div>
          <div class="flex-row flex-align-center padded">
            <label>Min</label>
            <input
              @change="update({zoom_min: $event.target.value})"
              :value="value.zoom_min"
              min="0"
              step="0.1"
              max="100.0"
              type="number"
              placeholder="1"
            />
          </div>
          <div class="flex-row flex-align-center">
            <label>Max:</label>
            <input
              @change="update({zoom_max: $event.target.value})"
              :value="value.zoom_max"
              min="1"
              step="0.5"
              max="100.0"
              type="number"
              placeholder="4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popover from '@morphosis/base/components/Popover.vue';
import BackgroundList from '@/components/editor/storyObjects/lists/BackgroundList.vue';

export default {
  name: "BackgroundContent",
  components: {
    Popover,
    BackgroundList,
  },
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
      editLevel: null,
      action: 'list',
    };
  },
  computed: {
    background() {
      const content = this.value.content;
      const backgrounds = this.story.backgrounds;
      if (this.value.type === 'background' && content) {
        return backgrounds.find(b => {
          if (typeof b.id === 'number') {
            return b.id === parseInt(content, 10);
          }
          return b.id === content;
        });
      }
      return null;
    },
    color() {
      if (this.value.bgcolor) {
        return '#' + this.MODELS.theme.invertLum(this.value.bgcolor).getHexString();
      }
      return 'black';
    },
    useOverlayout() { return (this.story.settings || {}).overlayout || this.$root.overlayout; },
  },
  watch: {
    action() {
      if (this.action === 'select') {
        this.editLevel = '';
        this.action = 'list';
      }
    },
  },
  async mounted() {
  },
  methods: {
    update(content) {
      const zooms = { 'zoom': 0, 'zoom_min': 0, 'zoom_max': 4 };
      for (const key in content) {
        if (zooms[key]) {
          content[key] = Math.min(100, Math.max(zooms[key], content[key]));
        }
      }
      this.$emit('update', content);
    },
  }
}
</script>

<style scoped>
  .background-content > .flex-row {
    flex-wrap: wrap;
  }
  .direction .frame {
    flex: 1 1 auto;
    min-width: 50px;
    max-width: 100px;
    justify-content: center;
    align-items: flex-start;
  }
  .direction .frame img {
    max-width: 100%;
  }
  .bg {
    min-height: 100px;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .bw p {
    cursor: pointer;
    color: black;
    background: rgba(255, 255, 255, 0.4);
  }
  .bg-content {
    flex: 100 100 auto;
    min-width: 300px;
  }
  .background-color {
    background-color: #333;
    color: #EEE;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .background-color span {
    padding: 0 5px;
    display: inline-block;
  }
  .explore-zoom > * {
    flex: 0 0 auto;
  }
  .explore-zoom input{
    width: 3em;
    text-align: right;
  }
</style>
